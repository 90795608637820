<script setup lang="ts">
import { useCarousel } from './useCarousel'
import type { WithClassAsProps } from './types'

const props = defineProps<WithClassAsProps>()

const { orientation, canScrollPrev, scrollPrev } = useCarousel()

const hidden = ref(true)

onMounted(() => {
  hidden.value = false
})
</script>

<template>
  <button
    :disabled="hidden || !canScrollPrev"
    :class="cn('p-2', { 'rotate-90': orientation === 'vertical' }, props.class)"
    aria-label="Previous slide"
    @click="scrollPrev"
  >
    <slot>
      <span
        class="bg-primary group-disabled:bg-disabled flex items-center justify-center rounded-full p-2"
      >
        <Icon
          name="fa6-solid:arrow-left"
          class="text-primary-contrast group-disabled:text-disabled-contrast"
          size="20"
        />
      </span>
    </slot>
  </button>
</template>
