<script setup lang="ts">
import { useCarousel } from './useCarousel'
import type { WithClassAsProps } from './types'

const props = defineProps<WithClassAsProps>()

const { orientation, canScrollNext, scrollNext } = useCarousel()

const hidden = ref(true)

onMounted(() => {
  hidden.value = false
})
</script>

<template>
  <button
    :disabled="hidden || !canScrollNext"
    :class="cn('p-2', { 'rotate-90': orientation === 'vertical' }, props.class)"
    aria-label="Next slide"
    @click="scrollNext"
  >
    <slot>
      <span
        class="group-disabled:bg-disabled bg-primary flex items-center justify-center rounded-full p-2"
      >
        <Icon
          name="fa6-solid:arrow-right"
          class="group-disabled:text-disabled-contrast text-primary-contrast"
          size="20"
        />
      </span>
    </slot>
  </button>
</template>
